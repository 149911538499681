import React from 'react'
import GatsbyImg from 'gatsby-image'

import theme from '../styles/theme'

import Link from './Link'

const SquareLink = ({
  children,
  color = theme.colors.grey_850,
  solid,
  img,
  ...props
}) => (
  <Link
    out
    css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      background: solid ? theme.colors.grey_850 : 'rgba(255, 255, 255, .1)',
      transition: 'background .3s',
      transitionDelay: solid ? '0s' : '.6s',
      overflow: 'hidden',
      '> *': {
        position: 'relative',
      },
      '&::after': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
      },
    }}
    {...props}
  >
    <GatsbyImg
      fluid={img}
      backgroundColor={color}
      css={{
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute !important',
        top: 0,
        left: 0,
        transform: 'scale(1.1)',
        opacity: 0,
        transition: '.75s cubic-bezier(.8, 0, .2, 1)',
        '*:hover > &': {
          transform: 'scale(1.01)',
          opacity: 1,
        },
      }}
    />
    {children}
  </Link>
)

export default SquareLink
