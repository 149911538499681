import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import { Power4 } from '../lib/gsap'
import { useTimeline } from '../hooks/animation'
import useScrolledBelow from '../hooks/useScrolledBelow'
import { rem, media, supportsGrid } from '../styles/tools'
import theme from '../styles/theme'
import { isBrowser } from '../utils'

import SEO from '../components/SEO'
import Container from '../components/Container'
import Space from '../components/Space'
import ScrollArrow from '../components/ScrollArrow'
import Link from '../components/Link'
import Footer from '../components/Footer'
import StaggerTriad from '../components/StaggerTriad'
import { Headline, SectionTitle, WideLink } from '../styles/typography'

import Motiv from '../components/logos/Motiv'
// import LosAngelesLakers from '../components/logos/LosAngelesLakers'
// import DisneyPlus from '../components/logos/DisneyPlus'
import ESPN from '../components/logos/ESPN'
// import Rams from '../components/logos/Rams'
import AcHotels from '../components/logos/AcHotels'
import Nestea from '../components/logos/Nestea'
import LAFC from '../components/logos/LAFC'
import FOX from '../components/logos/FOX'
import AdVictorem from '../components/logos/AdVictorem'

import avFlagVideo from '../assets/av-flag-video.mp4'

const IntroText = () => {
  const headlineID = isBrowser ? window.location.hash.slice(1) : false
  const defaultHeadline =
    'Shifting brands from\\ngiving up to moving\\nforward.'
  const [text, setText] = useState(headlineID ? null : defaultHeadline)
  const tl = useTimeline({ paused: true })

  useEffect(() => {
    if (headlineID) {
      const script = document.createElement('script')
      script.src = '/tabletop.min.js'
      script.onload = () => {
        window.Tabletop.init({
          key: '1wPc6Mu0uOV6u3g_0esAXmp2230uauE79vcat6oEINN0',
          simpleSheet: true,
          callback: data => {
            if (!data) return

            const requestedRow = data.filter(row => row.ID === headlineID)[0]

            if (requestedRow && requestedRow.HEADLINE) {
              setText(requestedRow.HEADLINE)
            } else {
              setText(defaultHeadline)
            }
          },
        })
      }
      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    if (!text) return

    const lines = headline.current.querySelectorAll('.line')
    tl.set(lines, { y: 50 })
    tl.staggerTo(
      lines,
      0.8,
      {
        y: 0,
        opacity: 1,
        ease: Power4.easeOut,
      },
      0.075,
      0
    )
    tl.restart()
  }, [text])

  const headline = useRef()

  return (
    <Headline
      as="h1"
      ref={headline}
      css={{
        fontSize: '9vw',
        '> .line': { opacity: 0 },
      }}
    >
      {text &&
        text.split('\\n').map((line, i) => (
          <div key={i} className="line">
            {line}
          </div>
        ))}
    </Headline>
  )
}

const Header = ({ shouldBeDark, ...props }) => (
  <header
    css={{
      display: 'flex',
      alignItems: 'center',
      minHeight: '550px',
      height: 'calc(100vh - 60px)',
      [media.tabletMd]: { height: '100vh' },
      position: 'relative',
      transitionProperty: 'color, background-color',
      transitionDuration: '.6s',
    }}
    style={{
      backgroundColor: shouldBeDark ? 'transparent' : '#fff',
      color: shouldBeDark ? '#fff' : theme.colors.black,
    }}
    {...props}
  />
)

export const query = graphql`
  query {
    flagPoster: file(relativePath: { eq: "av-flag-video-poster.png" }) {
      childImageSharp {
        fixed(width: 1920, quality: 90, toFormat: WEBP) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const PortfolioPage = ({ data }) => {
  const isBelow = useScrolledBelow(30)

  return (
    <>
      <SEO />
      <Header shouldBeDark={isBelow}>
        <Container>
          <IntroText />
          <ScrollArrow
            title="Scroll down"
            href="#content"
            offset={100}
            css={{
              position: 'absolute',
              bottom: rem(120 - 20),
              left: '50%',
              transform: 'translate(-50%)',
            }}
          />
        </Container>
      </Header>
      <Container as="section" id="content">
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            '> *': { width: '33.333%' },
            marginTop: rem(-60),
            [supportsGrid]: {
              '> *': { width: '100%' },
              display: 'grid',
              '--gap': rem(20),
              gridGap: 'var(--gap)',
              gridTemplateColumns: `repeat(var(--cols, 2), 1fr)`,
              [media.tablet]: { '--cols': 3 },
              [media.tabletMd]: { '--gap': rem(30) },
            },
          }}
        >
          <StaggerTriad autoPlay>
            <Motiv
              solid={!isBelow}
              href="https://advictorem.agency/case-studies/motiv-ring"
            />
            <AcHotels href="https://advictorem.agency/case-studies/ac-hotels" />
            <Nestea href="https://advictorem.agency/case-studies/nestea" />
          </StaggerTriad>
          <StaggerTriad>
            <ESPN href="https://advictorem.agency/case-studies/espn" />
            <LAFC href="https://advictorem.agency/case-studies/lafc" />
            <FOX
              href="https://advictorem.agency/case-studies/fox-digital"
              css={{ display: 'none', [media.tablet]: { display: 'flex' } }}
            />
          </StaggerTriad>
        </div>
      </Container>
      <Space y={{ mobile: 100, tabletLg: 200 }} />
      <Container as="section">
        <SectionTitle
          as="h1"
          viewBox="0 0 1399.66 132.6"
          d="M1397.7 5.6h-58.5L1312 48.3l-27-42.7h-58.7l60.2 83V127h51V88.6l60.2-83zM1264.8 127V91.3h-84.5V5.6h-51V127h135.5zM1124.9 5.6H979V42h47.4v85h51V42h47.4V5.6h.1zm-198.2 0v63.6h-1L875.1 5.6h-58.6V127h47.9V62.7h1l51.2 64.3h58V5.6h-47.9zM723 36.5h86.5V5.6H672V127h138.5V96.5H723V79.9h82.1V51.8H723V36.5zM597.9 62.9h-38.1V42.1h38.1c9.7 0 12.9 3.4 12.9 10.5s-3.4 10.3-12.9 10.3zm44.9 19.5v-1.7c15.6-4.1 24.8-13.4 24.8-33.3 0-26.5-13.1-41.8-54.1-41.8H508.8V127h51V92.6h34.5L614 127h56.3l-27.5-44.6zM434.7 62.9h-38.1V42.1h38.1c9.7 0 12.9 3.4 12.9 10.5s-3.4 10.3-12.9 10.3zm44.8 19.5v-1.7c15.6-4.1 24.8-13.4 24.8-33.3 0-26.5-13.1-41.8-54.1-41.8H345.6V127h51V92.6h34.5l19.7 34.3h56.3l-27.6-44.5zM286.9 5.6v68.2c0 15.6-10.4 20.2-27.5 20.2-17.2 0-27.5-4.6-27.5-20.2V5.6h-51v68.3c0 44.5 29.8 57.5 78.5 57.5s78.5-12.9 78.5-57.5V5.6h-51zm-170.6 49h58.8C174.2 20.2 147.4 1 88.5 1S1 21.9 1 66.3s28.7 65.3 87.5 65.3 86-19.2 86.9-56.4h-58.8c-1 10.7-6.5 19-28.1 19s-31.8-10-31.8-27.9c0-17.8 10.2-27.9 31.8-27.9s26.9 8.3 27.8 16.2z"
          css={{ marginBottom: '-0.3em', fontSize: '6vw' }}
        >
          Currently
        </SectionTitle>
        <div
          css={{
            position: 'relative',
            zIndex: 0,
            overflow: 'hidden',
          }}
        >
          <video
            src={avFlagVideo}
            poster={data.flagPoster.childImageSharp.fixed.src}
            autoPlay
            loop
            playsInline
            muted
            css={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: -1,
              top: 0,
              left: 0,
              objectFit: 'cover',
              background: theme.colors.black,
            }}
          />
          <Headline
            css={{
              margin: `${rem(60)} ${rem(30)} ${rem(60)}`,
              [media.mobileLg]: { margin: `${rem(130)} ${rem(30)} ${rem(90)}` },
            }}
          >
            HELPING
            <br />
            BRANDS WIN.
          </Headline>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              padding: rem(30),
            }}
          >
            <WideLink
              as={Link}
              out
              href="https://advictorem.agency"
              css={{
                marginTop: rem(10),
                [media.mobileLg]: { marginLeft: 'auto' },
              }}
            >
              VISIT AD VICTOREM
            </WideLink>
            <AdVictorem
              css={{
                width: '2em',
                marginLeft: rem(10),
                [media.mobileLg]: { width: '4em' },
              }}
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default PortfolioPage
