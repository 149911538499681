import React, { useState, useRef, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Global } from '@emotion/core'

import { Power4 } from '../../lib/gsap'
import { useTimeline } from '../../hooks/animation'

import SquareLink from '../SquareLink'

const title = 'AC Hotels'
const color = '#c6c4b9'

const Logo = props => {
  const [globalColor, setGlobalColor] = useState()
  const tl = useTimeline({ paused: true })

  useEffect(() => {
    tl.to(svg.current, 0.5, {
      transformOrigin: 'center',
      scale: 1.3,
      opacity: 0,
      ease: Power4.easeIn,
    })
    tl.set(svg.current, {
      scale: 1,
      opacity: 1,
    })
    tl.set(ac.current, {
      transformOrigin: 'center',
      scale: 1.3,
      opacity: 0,
    })
    tl.set(hotels.current, {
      transformOrigin: 'center',
      scale: 1.3,
      opacity: 0,
    })
    tl.set(topFrame.current, {
      transformOrigin: 'center',
      scale: 1.3,
      opacity: 0,
    })
    tl.set(clipPath.current, {
      yPercent: -100,
    })
    tl.to(ac.current, 1, {
      scale: 1,
      opacity: 1,
      ease: Power4.easeInOut,
    })
    tl.to(
      hotels.current,
      1,
      {
        scale: 1,
        opacity: 1,
        ease: Power4.easeInOut,
      },
      '-=.9'
    )
    tl.to(
      topFrame.current,
      1,
      {
        scale: 1,
        opacity: 1,
        ease: Power4.easeInOut,
      },
      '-=.9'
    )
    tl.to(
      clipPath.current,
      1.2,
      {
        yPercent: 0,
        ease: Power4.easeOut,
      },
      '-=.2'
    )
    tl.timeScale(1.7)
  }, [])

  const onMouseOver = () => {
    setGlobalColor(color)
    tl.play()
  }
  const onMouseLeave = () => {
    setGlobalColor(null)
    tl.reverse()
  }

  const svg = useRef()
  const clipPath = useRef()
  const bottomFrame = useRef()
  const marriott = useRef()
  const topFrame = useRef()
  const ac = useRef()
  const hotels = useRef()

  return (
    <StaticQuery
      query={graphql`
        query {
          img: file(relativePath: { eq: "clients/ac.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <SquareLink
          title={title}
          color={color}
          img={data.img.childImageSharp.fluid}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          {...props}
        >
          {globalColor && (
            <Global styles={{ body: { backgroundColor: globalColor } }} />
          )}
          <svg
            ref={svg}
            viewBox="-10 -10 66.9 66.9"
            width="46%"
            fill="currentColor"
          >
            <clipPath id="ac-hotels-clip">
              <path ref={clipPath} d="M46.9,37.1H0v9.8h46.9V37.1L46.9,37.1z" />
            </clipPath>
            <g clipPath="url(#ac-hotels-clip)">
              <path
                ref={bottomFrame}
                d="M46.2,37.9v8.3H0.8v-8.3H46.2 M46.9,37.1H0v9.8h46.9V37.1L46.9,37.1z"
              />
              <path
                ref={marriott}
                d="M18,41.2v0.8c0,0.1,0,0.2,0.1,0.2c0.3,0.1,0.6,0.1,0.9-0.1c0.4-0.5,0.3-1.2-0.1-1.6c-0.2-0.1-0.4-0.2-0.6-0.2
  c-0.1,0-0.2,0.1-0.2,0.2S18,40.9,18,41.2 M22.7,42.2c0.3,0.1,0.7-0.2,0.7-0.5c0,0,0,0,0,0c0-0.3,0-0.5,0-0.8
  c-0.1-0.4-0.4-0.7-0.8-0.7c-0.3,0-0.3,0.1-0.3,0.3v1.2C22.2,42.1,22.2,42.2,22.7,42.2 M21.1,40h0.4c0.6,0,1.1,0,1.7,0
  c0.6,0,1,0.5,1,1c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.1,0.2,0,0.3c0.4,0.4,0.7,0.8,1,1.2
  c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.4-0.1,0.4-0.3c0-1.1,0-2.2,0-3.2c0-0.3-0.1-0.5-0.5-0.5l0.1-0.1h1.6l0,0c-0.4,0.1-0.4,0.3-0.4,0.6
  v2.9c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.4,0.3,0.4c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1h-0.2c-0.6,0-1.3,0-1.9,0c-0.5,0-1-0.2-1.2-0.6
  l-0.7-1c-0.2-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.1,0.1-0.1,0.2v1.1c0,0.3,0.1,0.4,0.4,0.4c0.1,0,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.2,0
  c-0.6,0-1.3,0-1.9,0c-0.5,0-0.9-0.2-1.2-0.6c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.3-0.5-0.5-0.8-0.3c0,0.5,0,0.9,0,1.4
  c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.2,0.1h-1.5c0.4-0.1,0.4-0.4,0.4-0.7v-3c0-0.4,0-0.4-0.4-0.5c0,0,0,0-0.1-0.1H17c0.5,0,0.9,0,1.4,0
  c0.3,0,0.7,0.1,1,0.2c0.5,0.2,0.7,0.8,0.5,1.2c0,0,0,0,0,0c-0.1,0.3-0.3,0.5-0.5,0.7c0,0.1-0.1,0.1-0.2,0.2c0.5,0.6,0.9,1.1,1.3,1.6
  c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.4,0,0.4-0.3s0-0.4,0-0.6c0-0.8,0-1.7,0-2.5c0-0.5,0-0.5-0.5-0.7 M14.8,42.5
  c-0.2-0.5-0.3-1-0.5-1.4h-0.1l-0.5,1.4L14.8,42.5z M14.4,39.8c0.1,0.2,0.2,0.4,0.3,0.7l1.2,2.9c0.2,0.5,0.3,0.6,0.8,0.8h-1.3
  c-0.1-0.3-0.2-0.7-0.3-1c-0.2-0.6-0.1-0.5-0.7-0.5c-0.8,0-0.8,0-1.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.1,0.4,0.3,0.5h-2.4
  c-0.1-1-0.2-1.9-0.4-2.8h-0.1c-0.4,0.9-0.9,1.9-1.4,2.8H9.2l-1.4-2.7l0,0c0,0.2,0,0.4-0.1,0.5c-0.1,0.5-0.1,1.1-0.2,1.7
  c0,0.3,0,0.4,0.3,0.4c0,0,0.1,0,0.1,0.1H6.6c0.7-0.2,0.5-0.8,0.6-1.3c0.2-1,0.3-1.9,0.5-2.9c0-0.1,0-0.1,0.1-0.3
  c0.1,0.1,0.2,0.2,0.2,0.3c0.4,0.9,0.9,1.8,1.3,2.8c0,0.1,0.1,0.2,0.2,0.3l1.6-3.3h0.1c0,0.2,0.1,0.4,0.1,0.6c0.1,1,0.3,2.1,0.5,3.2
  c0,0.2,0.1,0.4,0.4,0.5c0.3,0.1,0.5-0.1,0.6-0.3c0,0,0,0,0,0c0.4-0.8,0.7-1.7,1-2.5l0.4-1.1l0.2-0.3L14.4,39.8 M31.5,42.2
  c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.8-1-1.2-1.7-1.1c-0.6,0.1-1,0.5-1.1,1.1c-0.2,0.8,0,1.7,0.6,2.3c0.5,0.5,1.3,0.6,1.9,0.1
  c0.2-0.2,0.3-0.4,0.4-0.7C31.5,42.8,31.5,42.5,31.5,42.2 M32.3,42c0,1.2-0.9,2.3-2.2,2.3c0,0-0.1,0-0.1,0c-1.2,0-2.2-1-2.2-2.2
  c0-1.2,1-2.2,2.2-2.2c1.1-0.2,2.1,0.6,2.3,1.7C32.3,41.7,32.3,41.9,32.3,42 M36.3,40.7c-0.1-0.4-0.4-0.4-0.7-0.5h-0.2
  c-0.6,0-0.6,0-0.6,0.6c0,0.9,0,1.7,0,2.6c0,0.6,0,0.6,0.5,0.8h-1.6c0.4-0.1,0.4-0.4,0.4-0.7v-3c0-0.2-0.1-0.3-0.3-0.3s-0.3,0-0.5,0
  s-0.6,0-0.8,0.4c0-0.2,0-0.5,0.1-0.7h3.6L36.3,40.7z M39.4,44.2h-1.7c0.4-0.1,0.4-0.4,0.4-0.7c0-1,0-2,0-3c0-0.2-0.1-0.3-0.3-0.3
  h-0.3c-0.4,0-0.8-0.1-1,0.4l-0.1,0c0-0.2,0.1-0.5,0.1-0.7h3.6v0.8c-0.1-0.4-0.5-0.4-0.8-0.4s-0.5-0.1-0.6,0s-0.1,0.4-0.1,0.7
  c0,0.8,0,1.7,0,2.5C38.9,43.9,38.9,44,39.4,44.2"
              />
            </g>
            <path
              ref={topFrame}
              d="M46.2,0.8v36.4H0.8V0.8H46.2 M46.9,0H0v37.9h46.9V0L46.9,0z"
            />
            <path
              ref={ac}
              d="M40,11l-1.2,0.8c0-0.1-0.1-0.1-0.1-0.2c-0.9-1.7-2.5-2.8-4.4-3.2c-2.2-0.5-4.5,0-6.3,1.4c-1.2,0.9-2,2.2-2.3,3.7
c-0.5,1.9-0.1,4,1.1,5.6c1,1.3,2.4,2.2,4,2.5c1.5,0.3,3,0.2,4.4-0.2c1.5-0.5,2.8-1.5,3.6-2.8c0.3-0.5,0.3-0.5,0.9-0.3l0.7,0.4
c0,0.1-0.1,0.2-0.1,0.2c-1.2,2-3.1,3.5-5.4,4c-1.2,0.3-2.5,0.3-3.8,0.2c-1.2-0.1-2.3-0.5-3.4-1c-1.8-1-3-2.7-3.5-4.6
c-0.6-1.8-0.4-3.8,0.4-5.6c0.8-1.9,2.4-3.4,4.3-4.1C31.5,6.4,34.5,6.6,37,8C38.3,8.7,39.3,9.7,40,11 M19.3,17.4l-4.4-8.7l-4.3,8.7
H19.3z M23.4,22.6c-0.5,0-1,0-1.5,0c-0.1,0-0.2-0.1-0.2-0.2c-0.6-1.1-1.1-2.3-1.7-3.4c-0.1-0.2-0.3-0.3-0.5-0.3c-3.1,0-6.1,0-9.2,0
c-0.2,0-0.4,0.1-0.4,0.3c-0.6,1.2-1.1,2.3-1.7,3.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.4,0-0.8,0-1.3,0l0.3-0.7l3.6-7.4
c1.1-2.3,2.3-4.6,3.4-6.9c0.1-0.2,0.3-0.3,0.5-0.3c0.4,0,0.7,0,1.1,0c0.1,0,0.3,0.1,0.3,0.2c1,2,2,3.9,2.9,5.9l4.6,9
C23.3,22.5,23.4,22.6,23.4,22.6"
            />
            <path
              ref={hotels}
              d="M16.8,28.7c0-0.9-0.6-1.6-1.5-1.6c-0.1,0-0.1,0-0.2,0c-0.9-0.1-1.7,0.6-1.7,1.5c-0.1,0.9,0.6,1.7,1.5,1.7
c0.1,0,0.2,0,0.3,0c0.8,0.1,1.6-0.5,1.7-1.4C16.8,28.8,16.8,28.8,16.8,28.7 M15.2,30.8c-0.8,0.1-1.5-0.2-2-0.8
c-0.7-0.9-0.5-2.3,0.4-3c0.2-0.2,0.4-0.3,0.7-0.3c0.8-0.3,1.7-0.2,2.4,0.3c1,0.7,1.2,2,0.5,3C16.7,30.5,16,30.9,15.2,30.8
M28.8,28.9h-2.3c-0.5,0-0.5,0-0.5,0.5v0.7h3c0.3,0,0.3,0.1,0.3,0.3s0,0.3-0.3,0.3c-1.1,0-2.3,0-3.4,0h-0.2v-4H29v0.5h-3v1.1
c0.1,0,0.2,0,0.3,0h2.2c0.4,0,0.4,0,0.4,0.4C28.8,28.8,28.8,28.8,28.8,28.9 M10,28.9H8.4c-0.3,0-0.6,0-0.9,0s-0.3,0-0.3,0.3
c0,0.5,0,0.9,0,1.4c0,0.2-0.1,0.3-0.3,0.3H6.6v-4.1c0.2,0,0.4,0,0.6,0c0.1,0,0.1,0.1,0.1,0.2c0,0.4,0,0.8,0,1.2
c0,0.2,0.1,0.3,0.3,0.3h2.2c0.2,0,0.3,0,0.3-0.3c0-0.5,0-0.9,0-1.4h0.7c0,0.1,0,0.2,0,0.2c0,1.2,0,2.4,0,3.5c0,0.2-0.1,0.3-0.3,0.3
H10V28.9z M36.5,29.3l0.6-0.1c0,0.4,0.3,0.7,0.6,0.9c0.5,0.2,1,0.2,1.5,0c0.3-0.1,0.5-0.3,0.4-0.6c0-0.3-0.2-0.5-0.5-0.5
c-0.5-0.1-1-0.2-1.6-0.4c-0.7-0.1-0.9-0.5-0.9-0.9c0-0.5,0.3-0.9,0.8-1.1c0.6-0.2,1.3-0.2,1.9,0c0.5,0.2,0.8,0.6,0.8,1.1h-0.5
c0,0-0.1-0.1-0.1-0.1c-0.1-0.4-0.4-0.6-0.8-0.7c-0.4-0.1-0.8,0-1.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.2,0.1,0.3,0.3,0.4
c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.4,0.1,0.6,0.4,0.6,0.8c0.1,0.4-0.1,0.9-0.5,1.1c-0.8,0.4-1.8,0.4-2.7,0
C36.8,30.2,36.5,29.8,36.5,29.3 M19.5,27.2v-0.5h3.8v0.5h-1.5c0,0.1,0,0.2,0,0.3c0,1,0,2,0,2.9c0,0.2-0.1,0.3-0.3,0.3h-0.1
c-0.2,0-0.3,0-0.3-0.3c0-1,0-2,0-3c0-0.2-0.1-0.3-0.3-0.3C20.4,27.1,20,27.1,19.5,27.2 M31.2,26.6h0.6v3.1c0,0.5,0,0.4,0.4,0.4h2.2
c0,0,0,0,0,0.1c0.1,0.5,0.1,0.5-0.5,0.5h-2.8L31.2,26.6z"
            />
          </svg>
        </SquareLink>
      )}
    />
  )
}

export default Logo
