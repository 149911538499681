import React, { useEffect, Children } from 'react'
import { useInView } from 'react-intersection-observer'

import { Power4 } from '../lib/gsap'
import { useTimeline } from '../hooks/animation'

const StaggerTriad = ({ children, autoPlay = false }) => {
  const tl = useTimeline(autoPlay ? { delay: 1 } : { paused: true })
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-20% 0px',
  })
  const refs = []

  useEffect(() => {
    inView && tl.play()
  })

  useEffect(() => {
    tl.set(refs, { y: 50 })
    tl.staggerTo(
      refs,
      1,
      {
        y: 0,
        opacity: 1,
        ease: Power4.easeOut,
      },
      0.1
    )
  }, [])

  return Children.map(children, (child, i) => (
    <div
      ref={ref => {
        if (i === 0) {
          inViewRef(ref)
        }
        refs.push(ref)
      }}
      css={{ opacity: 0 }}
    >
      {child}
    </div>
  ))
}

export default StaggerTriad
